import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { ColorPalette, TypeScale, TypeStyle } from "@theme-ui/style-guide";
import Layout from "../components/layout-content";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sector design system`}</h1>
    <p>{`A Theme UI-based design system ensures that colors and
typography are uniform. The current theme values aren't
set in stone and are subject to change.`}</p>
    <h2>{`Color palette`}</h2>
    <p>{`Currently the color palette is a slight adjustment to
Theme UI's system preset. It's likely that the primary,
secondary, and accent colors will be replaced.`}</p>
    <ColorPalette mdxType="ColorPalette" />
    <h2>{`Type scale`}</h2>
    <p>{`Using a type scale ensures consistency. The smaller steps
in the type scale should be used sparingly because they
can be difficult to read.`}</p>
    <TypeScale mdxType="TypeScale" />
    <h2>{`Typography`}</h2>
    <p>{`There are three primary typography variants and a monospace
font family.`}</p>
    <h3>{`Display`}</h3>
    <p>{`The display variant is used in hero sections and the `}<inlineCode parentName="p">{`h1`}</inlineCode>{` element
on MDX pages.`}</p>
    <TypeStyle fontSize={8} fontFamily="heading" fontWeight="display" lineHeight="heading" mdxType="TypeStyle">
  The quick brown fox jumps over the lazy dog
    </TypeStyle>
    <h3>{`Heading`}</h3>
    <p>{`The heading variant is used for `}<inlineCode parentName="p">{`h2-h6`}</inlineCode>{` elements, or anywhere
that we might want something that appears like a header.`}</p>
    <TypeStyle fontFamily="heading" fontWeight="heading" lineHeight="heading" mdxType="TypeStyle">
  The quick brown fox jumps over the lazy dog
    </TypeStyle>
    <h3>{`Body`}</h3>
    <p>{`The body variant is for the primary textual content on a page
including paragraphs, buttons, etc.`}</p>
    <TypeStyle fontFamily="body" fontWeight="body" lineHeight="body" mdxType="TypeStyle">
  The quick brown fox jumps over the lazy dog
    </TypeStyle>
    <h3>{`Monospace`}</h3>
    <p>{`The monospace font family is used for `}<inlineCode parentName="p">{`code`}</inlineCode>{` and `}<inlineCode parentName="p">{`pre`}</inlineCode>{`
tags.`}</p>
    <TypeStyle fontSize={3} fontFamily="monospace" mdxType="TypeStyle">
  console.log('hello, world!')
    </TypeStyle>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      